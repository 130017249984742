import React from "react";

const twitter = 'https://twitter.com/lukasmomcoin';
const telegram = 'https://t.me/lukasmomcoin';
const buynow = 'https://dexscreener.com/solana/3mo58mkeapb2asd59v8prpzwzfxz8vf8sv752ekb5nqc';

export const Footer = (props) => {
  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <div className="logo">
            <img src="img/logo.png" alt="" />
          </div>
          <div className="social-links">
            <a target="_blank" rel="noreferrer" href={twitter}><button class="bn632-hover bn24">Twitter</button></a>
            <a target="_blank" rel="noreferrer" href={telegram}><button class="bn632-hover bn24">Telegram</button></a>
          </div>
          <div className="buy-now">
            <a target="_blank" rel="noreferrer" href={buynow}><button class="bn634-hover bn21">Buy now</button></a>
          </div>{" "}
          <p>$LULU has no association with real life people. This token is simply a meme coin with no intrinsic value or expectation of financial return. $LULU was made for entertainment purposes only and has no value. Don't risk money you can't afford to lose. $LULU developers are not responsible for the price of the token.</p>
        </div>
      </div>
    </div>
  );
};
