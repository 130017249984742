import React from "react";

const twitter = 'https://twitter.com/lukasmomcoin';
const telegram = 'https://t.me/lukasmomcoin';
const buynow = 'https://dexscreener.com/solana/3mo58mkeapb2asd59v8prpzwzfxz8vf8sv752ekb5nqc';

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}
                  <br></br><span className="ca" >CA: {props.data ? props.data.ca : "Loading"}</span>
                </p>
                {/* Social links here */}
                <div className="social-links row">
                  <div className="col-md-6">
                    <a target="_blank" rel="noreferrer" href={twitter}><button class="bn632-hover bn24">Twitter</button></a>
                  </div>
                  <div className="col-md-6">
                  <a target="_blank" rel="noreferrer" href={telegram}><button class="bn632-hover bn24">Telegram</button></a>
                  </div>
                </div>
                <div className="buy-now">
                  <a target="_blank" rel="noreferrer" href={buynow}><button class="bn634-hover bn21">Buy now</button></a>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
