import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Anthem</h2>
          <p>
            Vibe to our anthem, made with love for Luka's mom and the community.
          </p>
        </div>
        <div className="row">
          <video className="videoanthem" controls playsinline preload="metadata" poster="img/preload.png">
            <source src="img/anthem.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};
